import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18next from "i18next";
import ar from '../src/Loocal/ar.json'
import en from '../src/Loocal/en.json'
import fr from '../src/Loocal/fr.json'
import { I18nextProvider } from "react-i18next";
import 'react-toastify/dist/ReactToastify.css';



const lang = localStorage.getItem("lang");
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'ar', // default language
  resources: {
    en: { translation: en },
    ar: { translation: ar },
    fr: { translation: fr },
  },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <I18nextProvider i18n={i18next}>

    <App />

    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
