import i18next, { t } from "i18next";
import React from "react";
import imagee3 from "../../../src/assets/shapes/22.jpg";
import imagee4 from "../../../src/assets/shapes/12.jpg";
export default class AboutOne extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <>
        <section className="about-one" id="about">
          {/* <div className="shape1 float-bob-y">
            <img
              src={publicUrl + "assets/images/shapes/about-v1-shape1.png"}
              alt="#"
            />
          </div>
          <div className="shape2 float-bob-y">
            <img
              src={publicUrl + "assets/images/shapes/about-v1-shape2.png"}
              alt="#"
            />
          </div> */}
          {/* <div className="shape3"></div> */}

          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-6 ">
                {i18next.language === "ar" ? (
                  <div className="w-100 ms-5">
                    <img className="w-100" src={imagee3} alt="about" />

                    {/*   <div className="about-one__bg-content">
                      <div className="img-box">
                        <div className="inner">
                          <img className="w-100" src={imagee2} alt="#" />
                        </div>
                      </div>
                    </div> */}
                  </div>
                ) : (
                  <div className="w-100 me-5">
                    <img className="w-100" src={imagee4} alt="about" />

                    {/*   <div className="about-one__bg-content">
                      <div className="img-box">
                        <div className="inner">
                          <img className="w-100" src={imagee2} alt="#" />
                        </div>
                      </div>
                    </div> */}
                  </div>
                )}
              </div>
              {/* Start About One Content */}
              <div className="col-xl-6 ">
                <div className="about-one__content ">
                  <div className="sec-title text-center ">
                    <div className="sec-title__tagline  ">
                    <span className="left"></span>
                      <h6>{t("aboutUs")}</h6>
                      <span className="right"></span>
                    </div>
                    <h2 className="sec-title__title fs-5 ">
                      {t("aboutusparagraph")}
                    </h2>
                  </div>

                  <div className="about-one__content-text1">
                    <p className="text1">{t("aboutparagraph2")}</p>
                    <p className="text2 fs-6">{t("aboutparagraph3")}</p>
                  </div>

                  <div className="about-one__content-text2"></div>

                  <div className="about-one__content-bottom d-flex align-items-center justify-content-center ">
                    <div className="btn-box ">
                      <a className="thm-btn" href="about">
                        <span className="txt">{t("discoverMore")}</span>
                        <i className="fa fa-angle-double-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* End About One Content */}
            </div>
          </div>
        </section>
      </>
    );
  }
}
