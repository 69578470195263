import { changeLanguage, t } from "i18next";
import React, { useEffect, useState } from "react";
import * as AiIcons from "react-icons/ai";
import { AiOutlineBars } from "react-icons/ai";
import { CgChevronDown, CgChevronLeft } from "react-icons/cg";
import { TbWorld } from "react-icons/tb";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Imagee from "../../assets/shapes/aaaaaaaa.png";
import { FaLinkedin, FaTiktok } from "react-icons/fa";
import { useRef } from "react";

import datanav from '../../data/datanav'
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const lang = i18next.language;
console.log(lang)

const SidebarLink = styled("a")`
  display: flex;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 50px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  &:hover {
    background: #123316;
    color: #fff;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 0px;
  color: #fff;
  font-weight: 400;
`;

const DropdownLink = styled(Link)`
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  &:hover,
  &:active {
    background: #123316;
    cursor: pointer;
    color: #fff;
  }
`;



const SubMenu = ({ item }) => {

  const [subnav, setSubnav] = useState(false);
    
  const showSubnav = () => setSubnav(!subnav);
  const {i18n}=useTranslation()
  console.log(i18n.language)




  return (
    <>
      <SidebarLink
        href={process.env.PUBLIC_URL + `${item.path}`}
        onClick={item.subNav && showSubnav}
        
      >
        <div>
          {/* {item.icon} */}
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>

        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((data, index) => (
          <DropdownLink
            to={process.env.PUBLIC_URL + `${data.path}`}
            key={index}
          >
            {item.icon}
            <SidebarLabel>{data.title}</SidebarLabel>
          </DropdownLink>
        ))}
    </>
  );
};

const NavIcon = styled(Link)`
  color: #123316;
  font-size: 2rem;
  height: 50px;
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

// const SidebarNav = styled.nav`
//   background-color: #3cab41;
//   width: 300px;
//   height: 100%;
//   position: fixed;
//   overflow-y: scroll;
//   scroll-behavior: smooth;
//   -webkit-scroll-behavior: smooth;
//   box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
//   top: 0;
//   right: ${({ sidebar }) => (sidebar  ?  "0" : "-100%")}; 
//   transition: 350ms;
//   z-index: 99999;
// `
const SidebarNav = styled.nav`
  background-color: #3cab41;
  width: 300px;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
  box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
  top: 0;

  /* Adjust the sidebar position based on language */
  ${({ sidebar, lang }) =>
     {console.log("lang=>",lang)
      return lang === "ar"
      ? `left: ${sidebar ? "0" : "-100%"};` 
      : `right: ${sidebar ? "0" : "-100%"};`} }

  transition: 350ms;
  z-index: 99999;
`;
;


const SidebarWrap = styled.div`
  width: 100%;
`;



const MobileMenu = () => {
  
 
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const sidebarRef = useRef(null);
  const {i18n}=useTranslation()

  // let publicUrl = process.env.PUBLIC_URL + "/";
 

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        // Clicked outside the sidebar, so close it
        setSidebar(false)
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []); // Empty dependency array ensures the effect runs once after the initial render

  const menuData = datanav()
 


  return (
    <>
      <>
        <NavIcon  style={{ justifyContent: "flex-end" }} onClick={() => setSidebar(true)}>
          <AiOutlineBars onClick={() => setSidebar(true)} />
        </NavIcon>
        <SidebarNav sidebar={sidebar} lang={i18n.language} ref={sidebarRef}>
          <SidebarWrap>
            <div className="mobile-nav__content " style={{zIndex:99}} >
              <div className="logo-box d-flex justify-content-between ">
                <Link to={process.env.PUBLIC_URL + `/`} aria-label="logo image">
                  <img src={Imagee} width="155" alt="" />
                </Link>
                <NavIcon to="#">
                <AiIcons.AiOutlineClose
                  style={{
                    color: "white",
                    fontSize: "18px",
                    justifyContent: "flex-start",
                  }}
                  onClick={showSidebar}
                />
              </NavIcon>
              </div>
              
              {menuData.map((item, index) => (
                <div 
                 key={index} onClick={()=>{showSidebar()}} >
                <SubMenu item={item} key={index}
                 />
                
                </div>
              ))}
               <div className="btn-group ">
          <button
            type="button"
            className="btn btn-transparent border-0  "
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <TbWorld color="#0b3d2c" fontSize="2em" />
          </button>
          <ul className="dropdown-menu p-1">
            <li>
              <a
                className="dropdown-item p-3"
                href="#"
                onClick={() => {
                  changeLanguage("en");
                  setSidebar(false); // Close sidebar after language change
                }}
              >
             English
              </a>
            </li>
            <li>
              <a
                className="dropdown-item p-3"
                href="#"
                onClick={() => {
                  changeLanguage("fr");
                  setSidebar(false); // Close sidebar after language change
                }}
              >
                French
              </a>
            </li>
            <li>
              <a
                className="dropdown-item p-3"
                href="#"
                onClick={() => {
                  changeLanguage("ar");
                  setSidebar(false); // Close sidebar after language change
                }}
              >
              Arabic
              </a>
            </li>
          </ul>
        </div>
              <ul className="mobile-nav__contact list-unstyled">
                <li>
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  <a href="mailto:greenship.crops@gmail.com">
                    greenship.crops@gmail.com
                  </a>
                </li>
                <li>
                  <i className="fa fa-phone" aria-hidden="true"></i>
                  <a href="tel:01111123059">+20 01111123059</a>
                </li>
              </ul>
              <div className="mobile-nav__top">
                <div className="mobile-nav__social">
                  <li>
                    <Link
                      to={
                        process.env.PUBLIC_URL +
                        `https://twitter.com/Shipgreen5Ship`
                      }
                    >
                      <span class="icon-twitter"></span>
                    </Link>
                  </li>
                  <li>
                    <Link
                    className="me-2"
                      to={
                        process.env.PUBLIC_URL +
                        `https://www.facebook.com/profile.php?id=100091618083019&mibextid=hIlR13`
                      }
                   
                   >
                      <span class="icon-facebook"></span>
                    </Link>
                  </li>
                  <li>
                    <Link
                    
                      to={
                        process.env.PUBLIC_URL +
                        `https://www.linkedin.com/in/green-ship-02884a2ab/`
                      }
                      className="text-white me-2"
                    >
                      <FaLinkedin />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={
                        process.env.PUBLIC_URL +
                        `https://www.tiktok.com/@greenship3?lang=en`
                      }
                      className="text-white"
                      style={{ marginRight: "8px" }}
                    >
                      <FaTiktok />
                    </Link>
                  </li>
                </div>
              </div>
            </div>
          </SidebarWrap>
        </SidebarNav>
      </>
    </>
  );
};

export default MobileMenu;
