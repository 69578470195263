import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IoMdMail } from "react-icons/io";



function IconSocialContact() {
  const contactOnWhatsApp = () => {
    const phoneNumber = '201025040705'; // Replace with your phone number
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_self', 'noopener,noreferrer');
  }

  return (
    <div className="d-flex">
      <Link to="https://www.facebook.com/profile.php?id=100091618083019&mibextid=hIlR13">
        <div className="iconsociall ms-3 text-dark fs-5 ">
          <FaFacebookF />
        </div>
      </Link>
      <Link to="https://www.instagram.com/greenship_egypt/?fbclid=IwZXh0bgNhZW0CMTAAAR3ukmz_AHjvR87yGn9YOgBjXtKQZohnIzY16g-XFcJMHYnVL-TJM_AFz0E_aem_AZ8h3D37xa9rWHkhpcYHnUViwx6s0urpxc3IospRsGCi2narfyYVQ9wcQcn_sPkG1htlQIIOatwKx68aU5mrT_dU
">
        <div className="iconsociall ms-3 text-dark fs-5 cursor-pointer ">
          <AiFillInstagram/>
        </div>
      </Link>
      <Link to="mailto:info@greenshipcrops.com">
        <div className="iconsociall ms-3 text-dark fs-5 cursor-pointer ">
          <IoMdMail/>
        </div>
      </Link>
      <Link >
        <div onClick={contactOnWhatsApp} className="iconsociall ms-3 text-dark fs-5 cursor-pointer">
          <IoLogoWhatsapp />
        </div>
      </Link>
      <Link to="https://www.linkedin.com/in/green-ship-02884a2ab/">
        <div className="iconsociall ms-3 text-dark fs-5 cursor-pointer">
          <FaLinkedin />
        </div>
      </Link>
      <Link to="https://twitter.com/Shipgreen5Ship">
        <div className="iconsociall ms-3 text-dark fs-5 cursor-pointer">
          <FaTwitter />
        </div>
      </Link>
      <Link to="https://www.tiktok.com/@greenship3?lang=en">
        <div className="iconsociall ms-3 text-dark fs-5 cursor-pointer">
          <FaTiktok />
        </div>
      </Link>
    </div>
  );
}

export default IconSocialContact;
