import React from "react";
import imagee4 from "../../assets/shapes/كول سنتر.jpg";
import image9 from "../../assets/shapes/cccccccccol.jpg";
import i18next, { t } from "i18next";
import { Link } from "react-router-dom";

export default class ContactOne extends React.Component {
  componentDidMount() {
    const $ = window.$;

    $("select:not(.ignore)").niceSelect();
  }
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <>
        <section className="contact-one " id="contact">
          <div className="container">
            <div className="row ">
              <div className="col-md-6 ">
                <div className="contact-one__form ">
                  <div className="sec-title text-center">
                    <div className="sec-title__tagline">
                    <span className="left"></span>
                      <h6>{t("Contact Us")}</h6>
                      <span className="right"></span>
                    </div>
                    <h2 className="sec-title__title">{t("contact")} </h2>
                  </div>

                  <form
                    id="contact-form"
                    name="contact_form"
                    className="default-form2  d-flex align-items-center justify-content-center"
                    action="#"
                    method="post"
                  >
                    <div className="contact-one__form-btn w-50 mt-5 ">
                      <Link to="/contact">
                        {" "}
                        <button
                          className="thm-btn"
                          type="submit"
                          data-loading-text="Please wait..."
                        >
                          <div className="txt">{t("BookNow")} </div>
                          <i className="icon-double-chevron"></i>
                        </button>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
              {i18next.language === "ar" ? (
                <div
                  className="col-md-6  my-4  wow slideInLeft"
                  data-wow-delay="500ms"
                  data-wow-duration="2500ms"
                >
                  {i18next.language === "ar" ? (
                    <img className="float-bob-y" src={image9} alt="#" />
                  ) : (
                    <img className="float-bob-y" src={imagee4} alt="#" />
                  )}
                </div>
              ) : (
                <div
                  className="col-md-6  my-4  wow slideInLeft"
                  data-wow-delay="500ms"
                  data-wow-duration="2500ms"
                >
                  {i18next.language === "ar" ? (
                    <img className="float-bob-y" src={image9} alt="#" />
                  ) : (
                    <img className="float-bob-y" src={imagee4} alt="#" />
                  )}
                </div>
              )}

              {/* Start Contact One Form */}

              {/* End Contact One Form */}
            </div>
          </div>
        </section>
      </>
    );
  }
}



