import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import WOW from "wowjs";
import { FaAngleUp } from "react-icons/fa";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "./Local.css";
import Iconsocial from "./components/iconsocial/iconsocial";
import { CategoryProvider } from "./components/context/categorycontext";

//Pages
import HomeDefault from "./pages/HomeDefault";
import HomeTwo from "./pages/HomeTwo";
import HomeThree from "./pages/HomeThree";
import About from "./pages/About";
import Service from "./pages/Service";
import ArborManagement from "./pages/ArborManagement";
import GardenManagement from "./pages/GardenManagement";
import NurseryTreeFarm from "./pages/NurseryTreeFarm";
import TrimmingPruning from "./pages/TrimmingPruning";
import PestsWeedsControl from "./pages/PestsWeedsControl";
import FruitsFlowersGarden from "./pages/FruitsFlowersGarden";
import Team from "./pages/Team";
import TeamDetails from "./pages/TeamDetails";
import PortfolioOne from "./pages/PortfolioOne";
import PortfolioTwo from "./pages/PortfolioTwo";
import Faq from "./pages/Faq";
import Blog from "./pages/Blog";
import BlogGrid from "./pages/BlogGrid";
import BlogDetails from "./pages/BlogDetails";
import Contact from "./pages/Contact";
import ScrollToTopRoute from "./components/scroll-to-top-route/ScrollToTopRoute";
import SearchMap from "./pages/searchmap";
import { ToastContainer } from 'react-toastify';


function App() {
 
  const lang = localStorage.getItem("language");
  const { t, i18n } = useTranslation();
  // localStorage.setItem("language", i18next.language);

  const language = i18next.language;
  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <div
      dir={i18next.language === "ar" ? "rtl" : "ltr"}
      className={`${i18next.language === "ar" ? "ar" : "en"} overflow-hidden`}
    >


      
      <Router>
        <ScrollToTopRoute />
        <CategoryProvider language={language}>
          <Routes>
            <Route path={`/`} exact element={<HomeDefault />} />
            <Route path={`/faq/:id`} exact element={<Faq />} />
            <Route path={`/home-two`} exact element={<HomeTwo />} />
            <Route path={`/home-three`} exact element={<HomeThree />} />
            <Route path={`/about`} exact element={<About />} />
            <Route path={`/searchmap`} exact element={<SearchMap />} />
            <Route path={`/services`} exact element={<Service />} />
            <Route
              path={`/arbor-management`}
              exact
              element={<ArborManagement />}
            />
            <Route
              path={`/garden-management`}
              exact
              element={<GardenManagement />}
            />
            <Route path={`/nursery`} exact element={<NurseryTreeFarm />} />
            <Route path={`/trimming`} exact element={<TrimmingPruning />} />
            <Route
              path={`/weeds-control`}
              exact
              element={<PestsWeedsControl />}
            />
            <Route
              path={`/flowers-garden`}
              exact
              element={<FruitsFlowersGarden />}
            />
            <Route path={`/team`} exact element={<Team />} />
            <Route path={`/team-details`} exact element={<TeamDetails />} />
            <Route path={`/portfolio-1`} exact element={<PortfolioOne />} />
            <Route path={`/portfolio-2`} exact element={<PortfolioTwo />} />

            <Route path={`/blog`} exact element={<Blog />} />
            <Route path={`/blog-grid`} exact element={<BlogGrid />} />
            <Route path={`/blog-details`} exact element={<BlogDetails />} />
            <Route path={`/contact`} exact element={<Contact />} />
          </Routes>
        </CategoryProvider>
        <div>
          <Iconsocial />
          <ScrollToTop
            className="scrollUp text-white fw-bolder"
            smooth
            top="10"
          />
        </div>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;


